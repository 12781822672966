<template>
  <div class="task-add">
    <div class="main-Title bgff"><h2>Edit Country</h2></div>
    <div class="mainBox">
      <el-card shadow="hover" v-loading="loading.page">
        <el-form
          ref="postData"
          :model="postData"
          :rules="rules"
          size="mini"
          label-width="80px"
          label-position="top"
        >
          <el-form-item label="国家代码" prop="countryCode"
            ><el-input v-model="postData.countryCode" placeholder="请输入国家代码"
          /></el-form-item>
          <el-form-item label="国家名称" prop="countryName"
            ><el-input v-model="postData.countryName" placeholder="请输入国家名称"
          /></el-form-item>
          <el-row :gutter="10">
            <el-col :span="24">
              <el-form-item>
                <span>speedConfig</span>
                <span style="color:#FF0000;padding-left: 20px;font-size: 12px;"
                  >*每小时比例之和是1，不要求一定是1</span
                >
              </el-form-item></el-col
            >
            <el-col :span="2" v-for="item in 24" :key="item">
              <el-form-item
                :label="item - 1 + ':00'"
                :prop="`speedConfig[${item - 1}]`"
                label-width="40px"
              >
                <el-input v-model="postData.speedConfig[item - 1]" placeholder="" />
              </el-form-item>
            </el-col>
          </el-row>
          <el-row :gutter="20">
            <el-col :span="12"
              ><el-button
                v-loading="loading.btn"
                type="success"
                style="width: 100%;"
                @click="add('postData')"
                >保存</el-button
              ></el-col
            >
            <el-col :span="12"
              ><el-button
                :loading="loading.btn"
                type="primary"
                style="width: 100%;"
                @click="goOff()"
                >取消</el-button
              ></el-col
            >
          </el-row>
        </el-form>
      </el-card>
    </div>
  </div>
</template>

<script>
  import { fetchCountryDetails, handleCountryUpdate } from '@/api/DDJ/v3/country.js';
  export default {
    data() {
      return {
        postData: {
          id: this.$route.query.id,
          speedConfig: {},
        },
        loading: {
          btn: false,
          page: false,
        },
        rules: {
          countryCode: [
            {
              required: true,
              message: '请输入国家代码',
              trigger: 'blur',
            },
          ],
          countryName: [
            {
              required: true,
              message: '请输入国家名称',
              trigger: 'blur',
            },
          ],
        },
      };
    },
    mounted() {
      this.getDetails();
    },
    created() {
      var numberAndNulls = (rule, value, callback) => {
        console.log(value);
        if (value !== '') {
          value = value * 1;
          setTimeout(() => {
            if (value > 1 || value < 0) {
              callback(new Error('只能输入小数'));
            } else {
              callback();
            }
          }, 0);
        } else {
          callback(new Error('不能为空！'));
        }
      };
      for (var i = 0; i < 24; i++) {
        this.$set(this.postData.speedConfig, i, 0);
        this.rules[`speedConfig[${i}]`] = [];
        this.rules[`speedConfig[${i}]`].push({ validator: numberAndNulls, trigger: 'blur' });
      }
    },
    methods: {
      // 获取详情
      getDetails() {
        this.loading.page = true;
        fetchCountryDetails({ id: this.postData.id }).then((res) => {
          this.postData = res.result;
          this.loading.page = false;
          this.postData.speedConfig = res.result.speedConfig
            ? JSON.parse(res.result.speedConfig)
            : {};
        });
      },
      // 保存
      add(formName) {
        this.$refs[formName].validate((valid) => {
          if (!valid) return;
          this.loading.btn = true;
          let query = Object.assign({}, this.postData);
          query.speedConfig = JSON.stringify(this.postData.speedConfig);
          handleCountryUpdate(query).then((res) => {
            console.log(res);
            this.loading.btn = false;
            this.$message({
              message: '更新成功',
              type: 'success',
            });
            this.$router.push({
              path: '/click/country/v3',
            });
          });
        });
      },
      goOff() {
        this.$router.push({
          path: '/click/country/v3',
        });
      },
    },
  };
</script>

<style></style>
